/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Firma Czamaninek stawia na ciągły rozwój i wychodzi naprzeciw oczekiwaniom klienta. Pragniemy poinformować o otwarciu naszego nowego Biura Handlowego w Toruniu!"), "\n", React.createElement(_components.p, null, "Wszystkie osoby zainteresowane współpracą zapraszamy serdecznie do umówienia spotkania:"), "\n", React.createElement("div", {
    class: "contact-card"
  }, React.createElement("div", {
    class: "contact-data"
  }, React.createElement("div", {
    class: "name"
  }, "Biuro Handlowe Toruń"), React.createElement("div", {
    class: "phone"
  }, React.createElement("a", {
    href: "tel:0048887228880"
  }, " +48 887 228 880")), React.createElement("div", {
    class: "phone"
  }, React.createElement("a", {
    href: "tel:0048723419476"
  }, " +48 723 419 476")), React.createElement("div", {
    class: "email"
  }, React.createElement("a", {
    href: "mailto:biuro.torun@czamaninek.pl"
  }, " biuro.torun@czamaninek.pl")))), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/img/nowe-biuro-handlowe-w-toruniu-2.jpg",
    alt: "Biuro Handlowe Toruń"
  }), "\n", React.createElement(_components.img, {
    src: "/img/nowe-biuro-handlowe-w-toruniu-3.jpg",
    alt: "Biuro Handlowe Toruń"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
